@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #47B2F1;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.auth-wrapper .form-control:focus {
  border-color: #47B2F1;
  box-shadow: none;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.login-image{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px !important;
  height: 80px;
  width: 100px;
}
td.print,
th.print,
tr.print,
table.print {
  width: 300px;
  border-top: 1px solid black;
  border-collapse: collapse;
  color: black !important;
  line-height: 1.1;
}

td.producto,
th.producto {
  width: 150px;
  max-width: 150px;
  color: black;
  line-height: 1.1;
}

td.cantidad,
th.cantidad {
  width: 75px;
  max-width: 75px;
  word-break: break-all;
  color: black;
  line-height: 1.1;
}

td.especificacion,
th.especificacion {
  width: 75px;
  max-width: 75px;
  word-break: break-all;
  color: black;
  line-height: 1.1;
}

td.precio,
th.precio {
  width: 75px;
  max-width: 75px;
  word-break: break-all;
  color: black;
  line-height: 1.1;
}

.centrado {
  text-align: center;
  align-content: center;
  color: black;
  line-height: 1.1;
}

.ticket {
  width: 300px;
  max-width: 300px;
  color: black;
  line-height: 1.1;
  margin-left: 4px;
}

img {
  max-width: inherit;
  width: inherit;
  line-height: 1.1;
}

.print-color {
  color: black;
  line-height: 1.1;
}

td.key,
th.key {
  width: 236px;
  color: black;
  line-height: 1.1;
}

td.value,
th.value {
  width: 140px;
  word-break: break-all;
  color: black;
  line-height: 1.1;
}

.hr {
  width: 300px;
  max-width: 300px;
  color: black;
  line-height: 1.5;
}

.tr {
  width: 376px;
  border-top: 1px solid black;
  border-collapse: collapse;
  color: black !important;
  line-height: 1.1;
}
div > .react-datepicker__input-container > .order-report {
  width: 150px !important;
}

.card-custom {
  height: 90vh;  
  background-color: white !important;
  border-bottom: white !important;
}

.card-header {
height: 8%;
}


.card-body {
height: 82%;
}

.card-footer {
height: 10%;
}

.rce-mbox-right{
background: #0078FF !important;
}

.rce-mbox-right>.rce-mbox-body{
color: white;
}

.rce-mbox-right-notch{
fill: #0078FF !important;
}

.rce-mbox-right>.rce-mbox-body>.rce-mbox-time{
color: white;
}

.rce-citem-avatar{
display: none !important;
}

.rce-container-citem{
text-align: left !important;
}

.rce-container-clist{
  color: #0078FF !important;
}

.daniel{
  background-color: aliceblue !important;
  background: aliceblue !important;
}
.react-datepicker-popper{
  z-index:99999 !important;
}

.react-datepicker__input-container > .form-control{
  width: 250px !important;
}
.z{
  z-index: 99999 !important;
}
.center-load{
  display: flex;
  height: 85%;
}
.center-load2{
  margin: auto;
}


* {
  box-sizing: border-box;
}
body {
  background: #fff !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #007bff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.custom-control-label {
  font-weight: 400;
}
.navbar-brand {
  color: azure !important;
}

.center {
  text-align: center;
}

#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  margin: 16px 28px 16px 0;
  float: left;
}

.site-layout-background {
  background: #fff;
  min-width: 250px !important;
}

.ant-layout {
  height: 90%;
  background: #fff;
  background-image: url(/backing-brosty.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.ant-table{
  background: none;
}

.print-source {
  display: none;
}

body > .print-source {
  display: block;
}

