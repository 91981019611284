@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}
body {
  background: #fff !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #007bff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.custom-control-label {
  font-weight: 400;
}
.navbar-brand {
  color: azure !important;
}

.center {
  text-align: center;
}

#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  margin: 16px 28px 16px 0;
  float: left;
}

.site-layout-background {
  background: #fff;
  min-width: 250px !important;
}

.ant-layout {
  height: 90%;
  background: #fff;
  background-image: url(/backing-brosty.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.ant-table{
  background: none;
}

.print-source {
  display: none;
}

body > .print-source {
  display: block;
}
