.card-custom {
  height: 90vh;  
  background-color: white !important;
  border-bottom: white !important;
}

.card-header {
height: 8%;
}


.card-body {
height: 82%;
}

.card-footer {
height: 10%;
}

.rce-mbox-right{
background: #0078FF !important;
}

.rce-mbox-right>.rce-mbox-body{
color: white;
}

.rce-mbox-right-notch{
fill: #0078FF !important;
}

.rce-mbox-right>.rce-mbox-body>.rce-mbox-time{
color: white;
}

.rce-citem-avatar{
display: none !important;
}

.rce-container-citem{
text-align: left !important;
}

.rce-container-clist{
  color: #0078FF !important;
}

.daniel{
  background-color: aliceblue !important;
  background: aliceblue !important;
}