td.print,
th.print,
tr.print,
table.print {
  width: 300px;
  border-top: 1px solid black;
  border-collapse: collapse;
  color: black !important;
  line-height: 1.1;
}

td.producto,
th.producto {
  width: 150px;
  max-width: 150px;
  color: black;
  line-height: 1.1;
}

td.cantidad,
th.cantidad {
  width: 75px;
  max-width: 75px;
  word-break: break-all;
  color: black;
  line-height: 1.1;
}

td.especificacion,
th.especificacion {
  width: 75px;
  max-width: 75px;
  word-break: break-all;
  color: black;
  line-height: 1.1;
}

td.precio,
th.precio {
  width: 75px;
  max-width: 75px;
  word-break: break-all;
  color: black;
  line-height: 1.1;
}

.centrado {
  text-align: center;
  align-content: center;
  color: black;
  line-height: 1.1;
}

.ticket {
  width: 300px;
  max-width: 300px;
  color: black;
  line-height: 1.1;
  margin-left: 4px;
}

img {
  max-width: inherit;
  width: inherit;
  line-height: 1.1;
}

.print-color {
  color: black;
  line-height: 1.1;
}

td.key,
th.key {
  width: 236px;
  color: black;
  line-height: 1.1;
}

td.value,
th.value {
  width: 140px;
  word-break: break-all;
  color: black;
  line-height: 1.1;
}

.hr {
  width: 300px;
  max-width: 300px;
  color: black;
  line-height: 1.5;
}

.tr {
  width: 376px;
  border-top: 1px solid black;
  border-collapse: collapse;
  color: black !important;
  line-height: 1.1;
}